import { Component } from 'react'

class Home extends Component {
    render() {
        return (
            <>
                <div className="row">
                    <div key="news" className="col-lg-12">
                        <div className="alert alert-danger">
                            <h1>The site is currently under reconstruction.</h1>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export { Home };
