export function Header() {
    let head = getHeader()
    return (
        <div className="" style={{ "maxWidth": "1200px", "margin": "auto" }}>
            <div className="bg-image-sm" style={{ "backgroundImage": "url(https://rosalie.cydoniansun.com/general_background_stars_sm.png)" }}>
                <a href={head.link}><img alt="header" style={{ "maxWidth": "100%" }} src={head.img} /></a>
            </div>
        </div>
    )
}

function getHeader() {
    let prefix = "https://ivar.cydoniansun.com/banner_v5_"
    let headers = [
        { "img": prefix + "1.png" },
        { "img": prefix + "kalmeria.png" },
    ]
    return headers[Math.floor(Math.random() * headers.length)]
}