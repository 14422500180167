import { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Header } from "./components/Header"

import { Home } from "./pages/Home"
import { ConfigProvider, FloatButton } from 'antd';

class App extends Component {
  render() {
    return (
      <ConfigProvider
      >
        <div className="App" style={{ "minHeight": "calc(100vh - 250px - 56px - 54px)" }} >
          <Router>
            <Header />
            <main className="container">
              <Switch>
                <Route component={Home} path="*" />
              </Switch>
            </main>
          </Router>
          <FloatButton.BackTop />
        </div>
      </ConfigProvider>
    )
  }
}

export default App;